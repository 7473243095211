import axios from 'axios'
import { useEffect, useState } from 'react'
import css from './Translations.module.css'
import { ReactComponent as Plus } from '../../assets/svg/plus.svg'
import { ReactComponent as Delete } from '../../assets/svg/trash-alt.svg'
import { ReactComponent as Edit } from '../../assets/svg/edit.svg'
import { AddTranslationModal } from './AddTranslationModal'
import { AnimatePresence } from 'framer-motion'
import { useSelector } from 'react-redux'
export const Translations = () => {

    const [translations, setTranslations] = useState([])
    const [addNewModal, displayAddNewModal] = useState(false)
    const lang = useSelector(s => s.Lang.currentLang)

    useEffect(() => {
        fetchTranslations()
    }, [])

    const fetchTranslations = async () => {
        axios.get('https://api.excellentprofy.info/getAllTranslations').then(response => setTranslations(response.data))
    }

    const getTranslationText = (el) => {
        return Object.keys(el).map((lang) => {
            console.log(el[lang])
            return <div className={css.langs}>{lang}: {el[lang]}</div>
        })
    }


    return (
        <>
            <div className={css.wrapper}>

                <div className={css.translations}>
                    <div className={css.top}>
                        <div className={css.title}>Перевод содержимого {lang}</div>
                        <div
                            onClick={() => { displayAddNewModal(true) }}
                            style={{ width: 'fit-content', padding: '0px 2em' }} className='button'>Добавить ключ</div>
                    </div>
                    {translations?.map((el, index) => {
                        return <>

                            {addNewModal && <AnimatePresence>
                                <AddTranslationModal hide={() => displayAddNewModal(false)} />
                            </AnimatePresence>
                            }
                            <div className={`${css.translation}`}>
                                <div className={css.TTitle}>Ключевое слово: {el.key}</div>
                                <div>{getTranslationText(el.translations)}</div>
                                <div className={css.icons}>
                                    <Plus style={{ fill: 'green' }} className={css.svg} />
                                    <Edit style={{ fill: 'yellowgreen' }} className={css.svg} />
                                    <Delete style={{ fill: 'salmon' }} className={css.svg} />
                                </div>
                            </div>
                        </>
                    })}


                </div>
            </div>
        </>
    )
}