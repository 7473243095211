import { motion } from 'framer-motion'
import { useState } from 'react'
import css from './AddTranslationModal.module.css'
import { ReactComponent as Plus } from '../../assets/svg/plus.svg'
import { ReactComponent as Save } from '../../assets/svg/save.svg'
import { ReactComponent as Delete } from '../../assets/svg/times.svg'

export const AddTranslationModal = ({ hide }) => {

    const [TKey, setKey] = useState('')
    const [group, setGroup] = useState()

    const [translations, setTranslations] = useState([{}])

    const showVariants = () => {
        return translations?.map((el, index) => {
            console.log(translations)
            return <div className={css.variants}>
                <input placeholder='ru' className={css.input}></input>
                <input onChange={e => setTranslations(prev => [...translations, prev[index] = e.target.value])} placeholder='Перевод на русском' className={css.input}></input>
            </div>
        })
    }

    return (
        <>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={hide} className={css.modal}>
                <motion.div onClick={e => e.stopPropagation()} className={css.topBar} initial={{ x: '-100vw' }} animate={{ x: 0, width: '100%' }} exit={{ x: '-100vw' }}>
                    <div> Добавление нового перевода </div>
                    <div className={css.icons}>
                        <div>
                            <Save style={{ width: '30px' }} />
                            Сохранить
                        </div>
                        <div onClick={() => hide()}>
                            <Delete style={{ width: '30px', fill: 'salmon' }} />
                            Отменить
                        </div>
                    </div>
                </motion.div>
                <div onClick={e => e.stopPropagation()} className={css.modalWrapper}>
                    <motion.div initial={{ height: 0 }} animate={{ height: 'fit-content' }} exit={{ height: 0 }} className={css.items}>
                        <div className={css.st}>
                            <div className={css.item}>
                                <label>Ключ</label>
                                <input value={TKey} onChange={(e) => setKey(e.target.value)} className={css.input}></input>
                            </div>
                            <div className={css.item}>
                                <label>Группа</label>
                                <input value={group} onChange={e => setGroup(e.target.value)} className={css.input}></input>
                            </div>
                        </div>
                        <div className={css.nd}>

                            <div>Варианты</div>
                            {showVariants()}
                            <div className={css.addVariants}>
                                <Plus style={{ fill: 'green', width: '50px' }} />
                                Добавить варианты
                            </div>
                        </div>
                    </motion.div>
                </div>
            </motion.div>
        </>
    )
}