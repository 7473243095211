export const yoigoPlans = [
    {
        caption: '10GB 10€',
        cost:10
    },
    {
        caption: '20GB 15€',
        cost:15
    },
    {
        caption: '25GB 20€',
        cost:20
    },
    {
        caption: '150GB 35€',
        cost:35
    }
]