import { motion } from "framer-motion"

export const FMPage = ({ Item, cb }) => {

    const APageSwitch = {
        initial: {
            x: 50,
            opacity: 0
        },
        enter: {
            x: 0,
            opacity: 1,
            transition: {
                duration: .6,
                delay: .2,
                when: 'beforeChildren',
            },
        },
        exit: {
            x: '100vw',
            transition: { duration: .2 },
        },
    }

    return (

        <motion.div initial='initial' animate='enter' exit='exit' variants={APageSwitch}>
            {<Item cb={cb} />}
        </motion.div>
    )
}