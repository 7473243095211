import axios from "axios"
import { useRef, useState } from "react"
import CanvasDraw from "react-canvas-draw"
import { APIURL } from "../../../../config"
import css from './AddSignatureModal.module.css'
export const AddSignatureModal = ({hide, id, cb}) => {

    const canvasProps = {
        loadTimeOffset: 5,

        brushRadius: 3,
        brushColor: "#444",
        catenaryColor: "#0a0302",
        gridColor: "rgba(150,150,150,0.17)",
        hideGrid: false,
        lazyRadius: 0,
        imgSrc: "",
        saveData: null,
        immediateLoading: true,
        hideInterface: false,
        gridSizeX: 25,
        gridSizeY: 25,
        gridLineWidth: 4.5,
        hideGridX: false,
        hideGridY: false,
    }

    const [signature, setSignature] = useState()
    const canvasRef = useRef()

    const submitSignature = () => {
        const url = APIURL + 'yoigoAddSignature'
        axios.post(url, {signature, id}).then(data => {
            if (data.data){
                cb(data.data.signature)
                hide()
            }
        })
    }

    return <>
        <div onClick={hide} className={css.wrapper}>
            <div onClick={(e)=>e.stopPropagation()} className={css.body}>
                <CanvasDraw onChange={() => setSignature(canvasRef.current.getDataURL('png'))} ref={canvasRef} className={css.canvas} {...canvasProps} />
                <div style={{ marginTop: '1em', background:'orange' }} onClick={()=>canvasRef.current.undo()} className='button'>
                    Отменить действие
                </div>
                <div style={{ marginTop: '1em', background: 'salmon' }} onClick={() => canvasRef.current.clear()} className='button'>
                    Очистить
                </div>
                <div style={{marginTop:'1em'}} onClick={submitSignature} className='button'>
                    Добавить подпись
                </div>
            </div>
        </div>
    </>
   
}