import { useEffect } from "react"
import { useSelector } from "react-redux"

export const TText = ({ children }) => {
    const lang = useSelector(s => s.Lang.currentLang)
    const text = useSelector(s => s.Lang.text)
    const getTranslation = () => {

        const el = text?.filter(e => e.key === children)[0]
        return el?.translations[lang] !== undefined || null ? el?.translations[lang] :
            el?.translations['ru']
    }
    return (<>

        {text.length > 0 && getTranslation()}

    </>)
}