import axios from 'axios'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { ReactComponent as Copy } from './clip.svg'
import { Loading } from '../../Loader/Loading'
import css from './Request.module.css'
import { APIURL } from '../../../config'
import { AddSignatureModal } from './addSignatureModal/AddSignatureModal'
import { UploadDocumentModal } from './uploadDocumentModal/UploadDoucmentModal'
export const RequestModal = ({ hide, item, setImageHandler }) => {

    const [sendEmail, setSendEmail] = useState(false)
    const [body, setBody] = useState('Ваша сим-карта успешно активирована!')
    const [email, setEmail] = useState(item.email)
    const [toDone, setToDone] = useState(true)
    const [isLoading, setLoading] = useState(false)
    const [addSignature, setAddSignature] = useState(false)
    const [uploadModal, setUploadModal] = useState(false)

    const submitEmail = async () => {
        setLoading(true)
        axios.post('https://api.piligrim.org/api/sendProfy', {
            email,
            subject: 'Активация сим карты',
            text: body
        }).then(response => {
            if (response?.data?.accepted?.length > 0) {
                if (toDone) submitDone()
                else alert('Уведомление доставлено!')
                setLoading(false)
                hide()
            }
            else {
                setLoading(false)
                alert('Ошибка при отправке уведомления!')
            }
        })
        // setLoading(true)
        // fetch('https://api.piligrim.org/api/sendGmail', {
        //     method: 'POST',
        //     body: {
        //         email,
        //         subject: 'Активация сим карты',
        //         text: body
        //     }
        // }).then(response => {
        //     response.json().then(data => {
        //         console.log(data)
        //         if (data?.accepted?.length > 0) {
        //             if (toDone) submitDone()
        //             else alert('Уведомление доставлено!')
        //             setLoading(false)
        //             hide()
        //         }
        //         else {
        //             setLoading(false)
        //             alert('Ошибка при отправке уведомления!')
        //         }
        //     })
        // })
    }


    const submitDone = () => {
        setLoading(true)
        axios.post(APIURL + 'changeStatusDone', {
            id: item._id
        }).then(response => {
            alert('Заявка отправлена  архив!')
            setLoading(false)

            if (response?.data) window.location.reload()
        })
    }

    const submitCreated = () => {
        axios.post(APIURL + 'changeStatusCreated', {
            id: item._id
        }).then(response => {
            alert('Статус заявки обновлён!')
            if (response?.data) window.location.reload()
        })
    }

    const signatureCallback = (e) => {
        item.signature.push(e)
    }

    const handleTemplate = e => setBody(e === 'success' ? 'Ваша сим-карта успешно активирована!' : 'При попытке активировать вашу сим карту произошла ошибка! Для активации карты необходим цветной скан(фотокопия) вашего документа сделанный через сканер на компьютере, иначе мы не можем зарегистрировать вас в системе!(мы не принимаем фото обработку под скан)')

    const handleStatus = e => {
        const value = e.target.value
        if (value == 'done') submitDone()
        else submitCreated()
    }

    return (

        <>
            {uploadModal && <UploadDocumentModal id={item?.id} cb={(e) => { item.document.push(e) }} hide={() => setUploadModal(false)} signatures={item.signature} />}
            {addSignature && <AddSignatureModal cb={signatureCallback} hide={() => setAddSignature(false)} id={item.id} />}
            {isLoading && <Loading />}
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={hide} className={css.modal}>
                {!sendEmail ? <>
                    <motion.div onClick={e => e.stopPropagation()} className={css.topBar} initial={{ x: '-100vw' }} animate={{ x: 0 }} exit={{ x: '-100vw' }}>
                        <div className={css.title}>Заявка №{item._id}</div>
                    </motion.div>

                    <div onClick={e => e.stopPropagation()} className={css.itemWrapper}>
                        <motion.div initial={{ height: 0 }} animate={{ height: 'fit-content' }} exit={{ height: 0 }} className={css.items}>
                            <div className={css.item}>Имя: {item?.firstname}<Copy onClick={e => navigator.clipboard.writeText(item.firstname)} /></div>
                            <div className={css.item}>Фамилия: {item?.lastname}<Copy onClick={e => navigator.clipboard.writeText(item.lastname)} /></div>
                            <div className={css.item}>Тарифный план: {item?.tplan} </div>
                            {/* {item?.amount && <div style={{ background: 'salmon' }} className={css.item}>Пополнить счёт на {item?.amount}€</div>} */}
                            <div className={css.item}>ICC: {item?.icc}<Copy onClick={e => navigator.clipboard.writeText(item.icc)} /></div>
                            <div className={css.item}>{item?.contactMethod}: {item?.contactValue}</div>
                            <div className={css.item}>Email: {item?.email}<Copy onClick={e => navigator.clipboard.writeText(item.email)} /></div>
                            <div onClick={() => setSendEmail(true)} className={`${css.item} button`}>
                                Отправить уведомление
                            </div>


                            {item.operator == 'yoigo' && <div className={css.signDocument}>

                                <div className={css.contractList}>
                                    {item?.document.map((el, index) => {
                                        return <div className={css.contract}>
                                            <a href={'https://v2.piligrim.org/yoigoContracts/' + el}>Скачать контракт {index + 1}</a>
                                        </div>
                                    })}
                                </div>

                                <div>

                                    <div onClick={() => setUploadModal(true)} className={css.button}>
                                        Генерировать новый контракт
                                    </div>
                                </div>

                            </div>}


                        </motion.div>

                        <motion.div initial={{ height: 0 }} animate={{ height: 'fit-content' }} exit={{ height: 0 }} className={css.items}>
                            <div className={css.item}>Куплено в городн: {item.boughtCity}, {item.boughtCountry}</div>
                            <div>
                                Загруженные удостоверения личности: {item.docImage.map((el, index) => {
                                    if (el.split('.').pop() !== 'pdf')
                                        return <div onClick={() => setImageHandler(`${APIURL}adminUploads/${el}`)}>
                                            <img width={100} height={70} src={`${APIURL}adminUploads/${el}`} alt={el.id} />
                                        </div>
                                    else return (
                                        <div className={css.done} style={{ width: '90%', height: '200%' }}>
                                            <div className={css.check}></div>
                                            <iframe
                                                src={`https://docs.google.com/viewer?url=${APIURL}adminUploads/${el}&embedded=true`}
                                                title="PDF Viewer"
                                                width="100%"
                                                height="100%"
                                                frameBorder="0"
                                            >
                                                <p>Your browser does not support iframes.</p>
                                            </iframe>
                                        </div>
                                    )
                                })}
                            </div>
                            <div>
                                Загруженные ICC: {item.iccImage.map((el, index) => {
                                    return <div onClick={() => setImageHandler(`${APIURL}adminUploads/${el}`)}>
                                        <img width={100} height={70} src={`${APIURL}adminUploads/${el}`} alt={el.id} />
                                    </div>
                                })}

                            </div>
                            {item.operator == 'yoigo' && <div className={css.signatureSection}>
                                <div>
                                    Подпись: {item.signature.map((el, index) => {
                                        return <div onClick={() => setImageHandler(`${APIURL}signatures/${el}`)}>
                                            <img width={100} height={70} src={`${APIURL}signatures/${el}`} alt={el.id} />
                                        </div>
                                    })}

                                </div>

                                <div className={css.button} onClick={() => setAddSignature(true)}>
                                    Добавить подпись
                                </div>
                            </div>}
                            <label>Статус</label>
                            <select onChange={handleStatus} value={item.status == 'created' ? 'created' : 'done'}>
                                <option value={'done'}>отработан</option>
                                <option value={'created'}>Не отработан</option>
                            </select>

                        </motion.div>
                    </div>
                </> :
                    <>
                        <motion.div onClick={e => e.stopPropagation()} className={css.topBar} initial={{ x: '-100vw' }} animate={{ x: 0 }} exit={{ x: '-100vw' }}>
                            <div className={css.title}>Отправить уведомлеие об активации</div>
                        </motion.div>
                        <motion.div onClick={e => e.stopPropagation()} className={css.messageWrapper} initial={{ height: 0 }} animate={{ height: 'fit-content' }} exit={{ height: 0 }} >
                            <motion.div initial={{ height: 0 }} animate={{ height: 'fit-content' }} exit={{ height: 0 }} className={css.items}>
                                <label style={{ margin: 0, padding: 0 }}>Шаблон: </label>
                                <select onChange={e => handleTemplate(e.target.value)}>
                                    <option value={'success'} onSelect={e => setBody('Ваша сим-карта успешно активирована!')}>Успешная активация</option>
                                    <option value={'error'} onSelect={e => alert('При попытке активировать вашу сим карту произошла ошибка! Для активации карты необхидимо фото вашего паспорта')}>Ошибка активации</option>
                                </select>
                                <label style={{ margin: 0, padding: 0 }}>Email: </label>
                                <input onChange={e => setEmail(e.target.value)} value={email} />
                                <label style={{ margin: 0, padding: 0 }}>Текст: </label>
                                <textarea onChange={e => setBody(e.target.value)} value={body}></textarea>
                            </motion.div>
                            <motion.div initial={{ height: 0 }} animate={{ height: 'fit-content' }} exit={{ height: 0 }} className={css.items}>
                                <label>Действие</label>
                                <div onClick={submitEmail} className='button'>Отправить</div>
                                <div onClick={() => setSendEmail(false)} style={{ background: 'orangered' }} className='button'>Отменить</div>
                                {item?.status === 'created' && <label>Отправить в Архив?
                                    <input onChange={e => setToDone(prev => !prev)} checked={toDone} type={'checkbox'}></input>
                                </label>}
                            </motion.div>

                        </motion.div>

                    </>}
            </motion.div>

        </>
    )
}