import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { APIURL } from '../../config'
import { TText } from '../../pages/Translations/langGetter'
import { Loading } from '../Loader/Loading'
import css from './UploadForm.module.css'
import { getCurrent, updateUser } from './userFieldHandler'
export const UploadForm = () => {

    const navigate = useNavigate()
    const [gotPassport, setPassport] = useState(false)
    const [gotIcc, setIcc] = useState(false)
    const [loading, setLoading] = useState()


    const handleSubmit = async () => {
        setLoading(true)
        const data = getCurrent()
        console.log(data)
        const response = await submit(data)
        if (response) {
            navigate('/success')
        }
    }

    const submit = async (data) => {
        console.log('da' + data)
        return new Promise((resolve, reject) => {
            axios.post(APIURL + 'createActivationRequest', data).then(response => {
                setLoading(false)
                localStorage.removeItem('user')
                resolve(response.data)
            })
        })
    }

    const upload = async (data) => {
        setLoading(true)
        return new Promise((resolve, reject) => {
            fetch(APIURL + 'uploadDoc', {
                method: 'POST',
                body: data
            }).then(response => {
                setLoading(false)
                resolve(response.json())
            })
        })
    }

    const uploadDocFiles = async (e) => {
        setLoading(true)
        const files = e.target.files
        const data = new FormData()
        for (let i = 0; i < files.length; i++) {
            data.append('images', files[i])
        }
        const response = await upload(data)
        updateUser('docImage', response)
        setPassport(response[0])

    }

    const uploadIcc = async (e) => {
        setLoading(true)
        const files = e.target.files
        const data = new FormData()
        for (let i = 0; i < files.length; i++) {
            data.append('images', files[i])
        }
        const response = await upload(data)
        updateUser('iccImage', response)
        setIcc(response[0])

    }

    const deletePassport = async () => {
        updateUser('docImage', [])
        setPassport(null)
    }
    const deleteIcc = async () => {
        updateUser('icc', [])
        setIcc(null)
    }


    return (
        <>
            {loading && <Loading />}
            <div className={css.wrapper}>

                <div className={css.col}>
                    <label>
                        {!gotPassport ?
                            <div className={css.unDone}></div> :
                            <div style={{ background: `url('${APIURL}uploads/${gotPassport}')` }} className={css.done}>
                                <div className={css.check}></div>
                            </div>
                        }
                        <input onChange={uploadDocFiles} type={'file'}></input>
                    </label>
                    {!gotPassport ?
                        <div className='subheader'>
                            <TText>uploadPassport2</TText>
                        </div> :
                        <div onClick={deletePassport} className={css.delete}>
                            <TText>delete</TText>
                        </div>
                    }
                </div>

                <div className={css.col}>

                    <label>
                        {!gotIcc ?
                            <div className={css.unDone}></div> :
                            <div style={{ background: `url('${APIURL}uploads/${gotIcc}')` }} className={css.done}>
                                <div className={css.check}></div>
                            </div>
                        }
                        <input onChange={uploadIcc} type={'file'}></input>
                    </label>
                    {!gotIcc ?
                        <div className='subheader'>
                            <TText>uploadICC</TText>
                        </div> :
                        <div onClick={deleteIcc} className={css.delete}>
                            <TText>delete</TText>
                        </div>
                    }

                </div>


            </div>{
                gotIcc && gotPassport &&
                <div className='center'>
                    <div onClick={handleSubmit} className='button'>
                        <TText>submitForReview</TText>
                    </div>
                </div>
            }
        </>
    )
}