import { motion } from 'framer-motion'
import css from './SideMenu.module.css'
export const SideMenu = () => {
    return (
        <>

            <motion.div initial={
                {
                    top: '100vh',
                    height: '500vh',
                    backgroundColor: 'rgba(100, 140, 190, .5)'
                }}
                animate={{

                    top: '5em',
                    backgroundColor: `rgba(200, 210, 230, 0.708)`,
                    transition: {
                    }
                }}
                className={css.wrapper}>
                <div className={css.placeholder}></div>
                <motion.nav>

                    <div>Главная</div>

                </motion.nav>
            </motion.div>
        </>
    )
}