import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { TText } from '../../pages/Translations/langGetter'
import { LangPicker } from '../LangPicker/LangPicker'
import { Hamburger } from '../SideMenu/Hamburger'

import { SideMenu } from '../SideMenu/SideMenu'
import css from './TopBar.module.css'
export const TopBar = () => {
    const [menu, showMenu] = useState(false)
    const navigate = useNavigate()
    return (
        <>
            {menu && <SideMenu />}
            <div className={css.wrapper}>

                <div onClick={() => navigate('/')} className={css.logo}>
                    <div className={css.logoText}>
                        <span className={css.st}>Excellent</span>
                        <span className={css.nd}>Profy.info</span>
                        <TText>test</TText>
                    </div>
                </div>


                <div className={css.ops}>

                    <LangPicker />

                    <img onClick={() => navigate('vodafone')} width={'40px'} height='40px' src='https://oferta.vodafone.es/vodafone-commonFiles-multi/imgs/menu/menu-logo-vf.png' />
                    {/* <Hamburger active={menu} action={() => showMenu(menu => !menu)} /> */}
                    <img onClick={() => navigate('movistar')} width={'40px'} height='40px' src='https://play-lh.googleusercontent.com/EDpSLNNK1ClR-5cNht1PpQwnBbFfTB38ZRroqy6slU1ikUcWbJxVI4IO05AcfyGsDCnJ=w240-h480-rw' />
                </div>
            </div>

        </>
    )
}