import { transform } from 'framer-motion'
import { useState } from 'react'
import css from './ImageModal.module.css'
export const ImageModal = ({ image, hide }) => {
    const [rotate, setRotate] = useState(0)
    return (
        <>
            <div className={css.wrapper}>
                <div className={css.modalBody}>
                    <div className={css.close}><span onClick={hide} style={{ cursor: 'pointer' }}>Закрыть [x]</span></div>
                    <div onClick={() => setRotate(prev => prev + 90)}>Повернуть на 90°</div>
                    <div style={
                        {
                            overflow: 'auto',
                            width: '100%',
                            height: '80vh',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            transform: `rotate(${rotate}deg)`,
                            background:'white'
                        }
                    }>
                        <img style={{background:'white'}} width={1000} height={700} src={image} />
                    </div>
                </div>
            </div>
        </>
    )
}