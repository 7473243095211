import axios from 'axios'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RequestItem } from '../../Components/Admin/Request/RequestItem'
import { RequestModal } from '../../Components/Admin/Request/RequestModal'
import { ImageModal } from '../../Components/ImageModal/ImageModal'
import { APIURL } from '../../config'

import css from './Admin.module.css'
export const Admin = () => {

    const navigate = useNavigate()
    const [requests, setRequests] = useState([])
    const [getParams, setParams] = useSearchParams()
    const [current, setCurrent] = useState(null)
    const [showAll, setShowAll] = useState(false)
    const [Image, setImage] = useState(false)

    useEffect(() => {
        if (getParams.getAll('login') == 'piligrim' && getParams.getAll('password') == 'qwerty') {
            axios.get(APIURL + 'getAllRequests').then(data => setRequests(data.data))
        } else {
            navigate('/')
        }

    }, [])


    return (
        <>
            {Image && <ImageModal hide={() => setImage(false)} image={Image} />}
            <AnimatePresence>
                {current && <RequestModal setImageHandler={e => setImage(e)} item={current} hide={e => setCurrent(null)} />}
            </AnimatePresence>
            <div className={css.wrapper}>
                <div className={css.title}>Новые заявки</div>
                <div className={css.uncompleted}>
                    {requests?.filter(el => el.status == 'created').reverse().map(el => {
                        return <RequestItem onClick={() => setCurrent(el)} item={el} />
                    })}

                </div>

                <div style={{ marginBottom: '2rem' }} onClick={() => setShowAll(!showAll)} className={css.title}>{showAll ? 'Скрыть ' : 'Показать '}архив заявок</div>
                {showAll && <div className={css.completed}>
                    {requests?.filter(el => el.status == 'done').reverse().slice(0, 4000).map(el => {
                        return <RequestItem setImageHandler={e => setImage(e)} onClick={() => setCurrent(el)} item={el} />
                    })}

                </div>}

            </div>
        </>
    )
}