import { APIURL } from '../../../config'
import css from './Request.module.css'
export const RequestItem = ({ onClick, item: el }) => {
    return (
        <div onClick={onClick} style={el.gotPayment ? { background: 'green' } : {}} className={css.request}>
            <div>{new Date(el.createdAt).toLocaleString()}</div>
            <div>Имя: {el.firstname}</div>
            <div>Фамилия: {el.lastname}</div>
            <div>Тарифный план: {el.tplan}</div>
            <div>ICC: {el.icc}</div>
            <div>{el.contactMethod}: {el.contactValue}</div>
            <div>Куплено в: {el.boughtCountry} Город: {el.boughtCity}</div>
            <div>
                Загруженные удостоверения личности: {el.docImage.map((el, index) => {
                    return <a target='_blank' href={`${APIURL}adminUploads/${el}`}>Паспорт{index + 1}</a>
                })}
            </div>
            <div>
                Загруженные удостоверения личности: {el.iccImage.map((el, index) => {
                    return <a target='_blank' href={`${APIURL}adminUploads/${el}`}>ICC{index + 1}</a>
                })}
            </div>
        </div>
    )
}