import axios from 'axios';
import { useEffect, useState } from 'react';
import { APIURL } from '../../../../config';
import css from './Upload.module.css'

export const UploadDocumentModal = ({ hide, id, signatures, cb }) => {


    const [file, setFile] = useState(null);
    const [fileOk, setFileOk] = useState(false)
    const [signature, setSignature] = useState()
    const [document, setDocument] = useState()
    const handleDrop = (e) => {

        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file.name.split('.').pop()=='pdf') {
            const file = e.dataTransfer.files[0];
            setFile(file.buffer);
        }
     else{
        alert('Документ должен быть в формате .pdf')
     }
      
      
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const submitRequest = () => {
        const formData = new FormData()
        formData.append('oldContract', file)
        formData.append('signature', signature)
        axios.post('https://v2.piligrim.org/pdf/signYoigoDocument', formData).then(data=>{
            if (data.data.document){
                setDocument(data.data.document)
            }
        })
    }

    useEffect(()=>{
        if (document){
            axios.post(APIURL +'addYoigoDocument', {id, document}).then(data=>{
                if (data.data){
                    cb(document)
                    hide()
                }
            })
        }
    }, [document])


    return <>
        <div onClick={hide} className={css.wrapper}>
           {!fileOk ? <div onClick={e => e.stopPropagation()} className={css.body}>
                {/* <label>
                    <input type={'file'} accept={'pdf'} hidden></input>
                    rere
                </label> */}


                <div
                    className={css.dropZone}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    {file ? (
                        <div className={css.uploaded}>
                            <p>Файл загружен!</p>
                            <p>{file.name}</p>

                        </div>
                    ) : (
                        <p>Для подписание контракта, Перетащите pdf файл в эту область, или нажмите на кнопку загрузки.</p>
                    )}
                    <div>
                        <label>
                            <div style={file?{background:'yellow', color:'black'}:{}} className={css.uploadButton}>{file ? 'Загрузить другой файл' : 'Загрузить файл'}</div>
                            
                            <input
                            accept='.pdf'
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                        </label>
                        {file && <div onClick={() => setFileOk(true)} style={{ marginTop: '10px' }} className={css.uploadButton}>Подтвердить файл</div>}
                    </div>

                </div>
            </div>:
            <div onClick={e=>e.stopPropagation()} className={css.body}>
                <div className={css.signatures}>
                    <p>Выберете подпись</p>
                    <div className={css.signatureList}>
                        {signatures.map((el, index) => {
                            return <div className={css.signature} >
                                <img key={index} onClick={()=>setSignature(el)} height={'50px'} src={`${APIURL}signatures/${el}`} alt={el.id} />
                            </div>
                        })}
                        </div>
                </div>
                {signature && <div style={{marginTop:'1em'}} className={css.uploadButton} onClick={()=>submitRequest()}>
                        Отправить
                    </div>}

                    {signature && 
                    <>
                    <p>Выбранная подпись:</p>
                        <img style={{marginTop:'1em'}} onClick={() => setSignature()} height={'50px'} src={`${APIURL}signatures/${signature}`} alt={'choosenSignature'} />
                    </>
                    }

                    
            </div>}
            </div>
        </>
}