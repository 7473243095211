import { createSlice } from "@reduxjs/toolkit";

const langSlice = createSlice({
    name: 'Lang',
    initialState: {
        currentLang: localStorage.getItem('lang') || 'ru',
        text: false
    },
    reducers: {
        setCurrentLang(state, { payload }) {
            localStorage.setItem('lang', payload)
            state.currentLang = payload
        },
        setText(state, { payload }) {
            localStorage.setItem('exText', JSON.stringify(payload))
            state.text = payload
        }
    }
})


export const { setCurrentLang, setText } = langSlice.actions
export default langSlice.reducer