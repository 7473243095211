import { TText } from '../Translations/langGetter'
import css from './Success.module.css'
export const Success = () => {
    return (
        <>
            <div className={css.wrapper}>
                <div className='title'><TText>gotRequest</TText></div>
                <div className={css.field}>
                    <div className='subheader'>
                        {/* В ближашее время ваша карта будет активирована. Вы получите уведомление об успешной активации. */}
                        <TText>gotRequestSuccess</TText>
                    </div>
                </div>
            </div>
        </>
    )
}