import { TText } from '../Translations/langGetter'
import css from './Contact.module.css'
export const Contact = () => {
    return (
        <>
            <div className={css.wrapper}>
                <div className='title'><TText>ourContacts</TText></div>
                <div className={css.field}>
                    <div className='subheader'>

                    </div>
                </div>
            </div>
        </>
    )
}