import { TText } from '../../pages/Translations/langGetter'
import css from './ActivationInfo.module.css'
export const ActivationInfo = ({ cb }) => {
    return (
        <>
            <div className={css.wrapper}>
                <div className='text'><TText>toActivate</TText></div>
                <ul>
                    <li><TText>passportPhoto</TText></li>
                    <li><TText>ICCPhoto</TText></li>
                </ul>
                <div className='center'>
                    <div onClick={cb} className='button'><TText>activateSim</TText></div>
                </div>
            </div>

        </>
    )
}