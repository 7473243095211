import axios from "axios";
import { useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { TopBar } from "./Components/TopBar/TopBar";
import { APIURL } from "./config";
import { WebRouter } from "./router/WebRouter";
import { setText } from "./Store/Slices/langSlice";
import Store from "./Store/Store";

function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    fetchTranslations()
  }, [])

  const fetchTranslations = async () => {
    axios.get('https://api.excellentprofy.info/getAllTranslations').then(response => dispatch(setText(response.data)))
  }

  return (
    <div className="App">

      <BrowserRouter>
        <TopBar />
        <WebRouter />
      </BrowserRouter>

    </div>
  );
}

export default App;
