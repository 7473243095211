import { nanoid } from '@reduxjs/toolkit'
import { useLocation } from 'react-router-dom'
import { TText } from '../../pages/Translations/langGetter'
import { plans } from '../../Store/Slices/plansSlice'
import css from './ActivationForm.module.css'
import { getCurrent, updateUser } from './userFieldHandler'
import {yoigoPlans} from './YoigoPlans'

export const YoigoActivationForm = ({ cb }) => {

    const location = useLocation()

    const checkData = () => {
        const {
            boughtCity,
            boughtCountry,
            contactMethod,
            contactValue,
            country,
            dob,
            docnum,
            firstname,
            icc,
            lastname,
            tplan,
            email

        } = getCurrent()
        updateUser('id', nanoid(7))
        if (icc?.length !== 19) {
            alert('Проверьте правильность ICC номера')
            return
        }
        if (!tplan) {
            alert('Не выбран тарифный план')
            return
        }
        if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            alert("Проверьте правильность введенного email адреса" + email)
            return
        }
        if (!firstname || !email || !lastname || !docnum || !icc || !dob || !country || !contactValue || !contactMethod || !contactValue || !boughtCity || !boughtCountry) {
            alert('Необходимо заполнить все обязательные поля')
            return
        }
        else cb()
    }

    return (
        <>
        
            <div className={css.wrapper}>
                <div>
                    <label><TText>firstname</TText></label>
                    {/* defaultValue={getCurrent('firstname')} */}
                    <input onChange={e => updateUser('firstname', e.target.value)}></input>
                </div>
                <div>
                    <label><TText>lastname</TText></label>
                    {/* defaultValue={getCurrent('lastname')}  */}
                    <input onChange={e => updateUser('lastname', e.target.value)}></input>
                </div>
                <div>
                    {/* defaultValue={getCurrent('dob')} */}
                    <label><TText>DOB</TText></label>
                    <input type={'date'} onChange={e => updateUser('dob', e.target.value)}></input>
                </div>
                <div>
                    {/* defaultValue={getCurrent('docnum')}  */}
                    <label><TText>docnum</TText></label>
                    <input onChange={e => updateUser('docnum', e.target.value)}></input>
                </div>
                <div>
                    <label><TText>nationality</TText></label>
                    {/* defaultValue={getCurrent('country')} */}
                    <input onChange={e => updateUser('country', e.target.value)}></input>
                </div>
                <div>
                    <label><TText>tPlan</TText></label>
                    <select defaultValue={getCurrent('tplan')} onChange={e => updateUser('tplan', e.target.value)} >
                        <option disabled hidden selected></option>
                        {location.pathname === '/movistar' ?
                            plans.map(el => {
                                return <option>{el.caption}</option>
                            })
                            :
                            yoigoPlans.map(el => {
                                return <option>{el.caption}</option>
                            })}
                    </select>
                </div>
                <div>
                    <label>ICC</label>
                    {/* defaultValue={getCurrent('icc')}  */}
                    <input placeholder='8934...' onChange={e => updateUser('icc', e.target.value)}></input>
                </div>
                <div>
                    <label><TText>contactMethod</TText></label>
                    {/* defaultValue={getCurrent('contactMethod')} */}
                    <select onChange={e => updateUser('contactMethod', e.target.value)}>
                        <option disabled hidden selected></option>
                        <option>WhatApp</option>
                        <option>Viber</option>
                        <option>Telegram</option>
                    </select>
                </div>
                <div>
                    <label><TText>contactValue</TText> @TELEGRAM_LOGIN</label>
                    {/* defaultValue={getCurrent('contactValue')} */}
                    <input onChange={e => updateUser('contactValue', e.target.value)}></input>
                </div>
                <div>
                    <label>Email</label>
                    {/* defaultValue={getCurrent('email')} */}
                    <input onChange={e => updateUser('email', e.target.value.replace(' ', '').trim())}></input>
                </div>
                <div>
                    <label><TText>boughtCountry</TText></label>
                    {/* defaultValue={getCurrent('boughtCountry')} */}
                    <input onChange={e => updateUser('boughtCountry', e.target.value)}></input>
                </div>
                <div>
                    <label><TText>boughtCity</TText></label>
                    {/* defaultValue={getCurrent('boughtCity')}  */}
                    <input onChange={e => updateUser('boughtCity', e.target.value)}></input>
                </div>
                <div onClick={checkData} className='center'>
                    <div className='button'><TText>next</TText></div>
                </div>
            </div>
        </>
    )
}