import { AnimatePresence } from 'framer-motion'
import { Route, Routes, useLocation } from 'react-router-dom'
import { FMPage } from '../Components/FM/FMPage'
import { Movistar } from '../pages/Activation/Movistar'
import { Vodafone } from '../pages/Activation/Vodafone'
import { Yoigo } from '../pages/Activation/Yoigo'
import { Admin } from '../pages/Admin/Admin'
import { Contact } from '../pages/Contact/Contact'
import { Main } from '../pages/Main/Main'
import { Reject } from '../pages/Reject/Reject'
import { Success } from '../pages/Success/Success'
import { Test } from '../pages/test/Test'
import { Translations } from '../pages/Translations/Translations'
export const WebRouter = () => {
    const location = useLocation()

    return (
        <AnimatePresence>

            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<FMPage Item={Main} />}></Route>
                <Route path='/vodafone' element={<FMPage Item={Vodafone} />} />
                <Route path='/movistar' element={<FMPage Item={Movistar} />} />
                <Route path='reject' element={<FMPage Item={Reject}/>}/>
                <Route path='/success' element={<FMPage Item={Success} />} />
                <Route path='/contact' element={<FMPage Item={Contact} />} />
                <Route path='/rootAdmin' element={<FMPage Item={Admin} />} />
                <Route path='/test' element={<FMPage Item={Test} />} />
                <Route path='/translations' element={<FMPage Item={Translations} />} />
                <Route path='/yoigo' element={<FMPage Item={Yoigo} />} />
            </Routes>

        </AnimatePresence>
    )
}