export const plans = {
    movistar: [
        {
            caption: 'Предоплата Тотал',
            price: '35',
            internet: '45',
            internetRouming: '95',
            minutes: 0,
            roumingTime: '120',
            more: []
        },
        {
            caption: 'Предоплата Премиум',
            price: '30',
            internetRouming: '40',
            internet: '80',
            minutes: '400',
            roumingTime: '120',
            more: []
        },
        {
            caption: 'Предоплата',
            price: '20',
            internetRouming: '30',
            internet: '40',
            minutes: '200',
            roumingTime: '120',
            more: []
        }
    ],
    vodafone: [
        {
            caption: 'XXL',
            price: '40',
            internetRouming: '30',
            internet: '170',
            minutes: 0,
            roumingTime: '120',
            more: []
        },
        {
            caption: 'XL',
            price: '30',
            internetRouming: '20',
            internet: '140',
            minutes: 0,
            roumingTime: '120',
            more: []
        },
        {
            caption: 'L',
            price: '20',
            internetRouming: '13',
            internet: '120',
            minutes: 0,
            roumingTime: '120',
            more: []
        },
        {
            caption: 'M',
            internetRouming: '10',
            price: '15',
            internet: '100',
            minutes: '800',
            roumingTime: '120',
            more: []
        },
        {
            caption: 'S',
            internetRouming: '7',
            price: '10',
            internet: '50',
            minutes: '300',
            roumingTime: '120',
            more: []
        },
        {
            caption: 'S Voz',
            price: '10',
            internetRouming: '7',
            internet: '40',
            minutes: 0,
            roumingTime: '120',
            more: []
        },
    ]
}