import { configureStore } from "@reduxjs/toolkit";
import langSlice from "./Slices/langSlice";

const Store = configureStore({
    devTools:false,
    reducer: {
        'Lang': langSlice,
        
    }
})

export default Store