
import { useNavigate } from 'react-router-dom'
import { TText } from '../Translations/langGetter'
import css from './Main.module.css'
export const Main = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className={css.wrapper}>
                <div className={css.startField}>
                    <div className={css.top}>
                        <div onClick={() => navigate('/movistar')} className='button'>
                            <TText>activateSim</TText>
                            {' '}Movistar
                        </div>
                        <div onClick={() => navigate('/vodafone')} className='button'>
                            <TText>activateSim</TText>
                            {' '} Vodafone
                        </div>
                        <div onClick={() => navigate('/yoigo')} className='button'>
                            <TText>activateSim</TText>
                            {' '} Yoigo
                        </div>
                    </div>

                    <div style={{ marginTop: '2em' }} onClick={() => navigate('/contact')} className='button'>
                        <TText>Feedback</TText>
                    </div>

                </div>
            </div>
        </>
    )
}