import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import css from './LangPicker.module.css'
import { LangPickerModal } from './LangPickerModal'



export const LangPicker = () => {
    const lang = useSelector(s => s.Lang.currentLang)
    const [modal, showModal] = useState(false)

    return (
        <>
            <AnimatePresence>
                {modal && <LangPickerModal current={lang} hide={() => showModal(false)} />}
            </AnimatePresence>
            <div onClick={e => showModal(true)} className={css.wrapper}>
                {lang}
            </div>
        </>
    )
}