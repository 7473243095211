import { AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'
import { ActivationForm } from '../../Components/Activation/ActivationForm'
import { ActivationInfo } from '../../Components/Activation/ActivationInfo'
import { UploadForm } from '../../Components/Activation/UploadForm'
import { FMPage } from '../../Components/FM/FMPage'
import { updateUser } from '../../Components/Yoigo/userFieldHandler'
import { TText } from '../Translations/langGetter'
import css from './Activation.module.css'
export const Vodafone = () => {
    useEffect(()=>{
        updateUser('operator', 'vodafone')
    },[])
    const [step, setStep] = useState(1)
    return (
        <>
            <div className={css.wrapper}>
                <div className='title'>
                    <TText>activateSim</TText> Vodafone
                </div>
                <AnimatePresence>
                    {step === 1 && <FMPage Item={ActivationInfo} cb={() => setStep(2)} />}
                </AnimatePresence>
                <AnimatePresence>
                    {step === 2 && <FMPage Item={ActivationForm} cb={() => setStep(3)} />}
                </AnimatePresence>
                <AnimatePresence>
                    {step === 3 && <FMPage Item={UploadForm} />}
                </AnimatePresence>
            </div>
        </>
    )
}