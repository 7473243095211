import { AnimatePresence, motion } from 'framer-motion'
import css from './LangPickerModal.module.css'
import { ReactComponent as LV } from '../../assets/svg/lv.svg'
import { ReactComponent as EN } from '../../assets/svg/gb.svg'
import { ReactComponent as RU } from '../../assets/svg/ru.svg'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentLang } from '../../Store/Slices/langSlice'



const getText = (value) => {

    switch (value) {
        case 'ru':
            return 'Выбор языка'
        case 'lv':
            return 'Valodas izvēle'
        case 'en':
            return 'Language selection'
        default: return 'Выбор языка'
    }
}

export const LangPickerModal = ({ hide, current }) => {

    const dispatch = useDispatch()
    const [text, setText] = useState(getText(current))
    const setLang = (lang) => {
        dispatch(setCurrentLang(lang))
        hide()
    }


    return (
        <>

            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={hide} className={css.wrapper}>
                <div style={{ transform: `translateY(-5em)` }} className='title'>
                    <AnimatePresence exitBeforeEnter={true} key={text}>
                        <motion.div initial={{ x: '-50vw' }} animate={{ x: 0 }} exit={{ opacity: 0, x: '100vh' }}>{text}:</motion.div>
                    </AnimatePresence>
                </div>
                <motion.div onClick={e => e.stopPropagation()} style={{ transform: `translateY(-12rem)` }} className={css.body}>

                    <div className={css.flags}>
                        <LV onClick={() => setLang('lv')} onMouseOver={e => setText(getText('lv'))} className={css.svg} />
                        <RU onClick={() => setLang('ru')} onMouseOver={e => setText(getText('ru'))} className={css.svg} />
                        <EN onClick={() => setLang('en')} onMouseOver={e => setText(getText('en'))} className={css.svg} />
                    </div>
                </motion.div>
            </motion.div>
        </>
    )
}