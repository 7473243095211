import { AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'
import { ActivationForm } from '../../Components/Activation/ActivationForm'
import { ActivationInfo } from '../../Components/Activation/ActivationInfo'
import { UploadForm } from '../../Components/Activation/UploadForm'
import { FMPage } from '../../Components/FM/FMPage'
import { TText } from '../Translations/langGetter'
import { ActivationInfoYoigo } from '../../Components/Yoigo/ActivationInfoYoigo'
import css from './Activation.module.css'
import { YoigoActivationForm } from '../../Components/Yoigo/YoigoForm'
import { YoigoUploadForm } from '../../Components/Yoigo/YoigoUpload'
import { YoigoSignatureAndPayment } from '../../Components/Yoigo/YoigoSignatureAndPayment'
import { updateUser } from '../../Components/Yoigo/userFieldHandler'
export const Yoigo = () => {
    useEffect(()=>{
        updateUser('operator', 'yoigo')
    },[])
    const [step, setStep] = useState(1)
    return (
        <>
            <div className={css.wrapper}>
                <div className='title'>
                    <TText>activateSim</TText> Yoigo
                </div>
                <AnimatePresence>
                    {step === 1 && <FMPage Item={ActivationInfoYoigo} cb={() => setStep(2)} />}
            
                    {step === 2 && <FMPage Item={YoigoActivationForm} cb={() => setStep(3)} />}
            
                    {step === 3 && <FMPage Item={YoigoUploadForm} cb={()=> setStep(4)} />}
                    
                    {step === 4 && <FMPage Item={YoigoSignatureAndPayment}/>}
                </AnimatePresence>
            </div>
        </>
    )
}