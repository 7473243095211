import { nanoid } from "@reduxjs/toolkit"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import CanvasDraw from 'react-canvas-draw'
import { useNavigate } from "react-router-dom"
import { TText } from "../../pages/Translations/langGetter"
import { updateUser } from "../Activation/userFieldHandler"
import { PaymentForm } from "./PaymentForm"
import css from './Sign.module.css'
import { getCurrent } from "./userFieldHandler"
import { YoigoSubmitDetails } from "./YoigoSubmitDetails"
import { yoigoPlans } from "./YoigoPlans"
import { APIURL } from "../../config"
export const YoigoSignatureAndPayment = () => {


    const [signature, setSignature] = useState()
    const [uploadedSignature, setUploadedSignature] = useState()
    const [amount, setAmount] = useState()
    const [redsysSignature, setRedsysSignature] = useState()
    const [params, setParams] = useState()
    const [plan, setPlan] = useState()
    const [submitButton, setSubmitButton] = useState(false)
    const navigate = useNavigate()
    const canvasRef = useRef()
    const submitSignature = () => {
        axios.post(APIURL + 'uploadSignature', { signature: signature }).then(data => {
            setUploadedSignature(data.data)
            console.log(data)
        })
    }

    const handleSubmit = () => {
        axios.post(APIURL + 'createYoigoActivationRequest', getCurrent()).then(data => navigate('/success'))
    }


    const canvasProps = {
        loadTimeOffset: 5,

        brushRadius: 3,
        brushColor: "#444",
        catenaryColor: "#0a0302",
        gridColor: "rgba(150,150,150,0.17)",
        hideGrid: false,
        lazyRadius: 0,
        imgSrc: "",
        saveData: null,
        immediateLoading: true,
        hideInterface: false,
        gridSizeX: 25,
        gridSizeY: 25,
        gridLineWidth: 4.5,
        hideGridX: false,
        hideGridY: false,
    }

    useEffect(() => {
        const current = getCurrent()
        const plan = yoigoPlans.filter(el => el.caption === current.tplan)[0]
        updateUser('amount', plan.cost <= 10 ? 0 : plan.cost - 10)
        if (uploadedSignature) {
            updateUser('signature', uploadedSignature)

            if (plan.cost > 10) {

                setAmount(plan.cost - 10)
                setSubmitButton(true)
            }
            else {
                setSubmitButton(true)
            }
        }

    }, [uploadedSignature])

    // useEffect(() => {
    //     if (amount)

    //         axios.post(APIURL + 'createYoigoActivationRequest', getCurrent()).then(datas => {
    //             axios.post(APIURL + 'createYoigoPayment', { amount: amount, id: getCurrent('id') }).then(data => {
    //                 setParams(data.data.params)
    //                 setRedsysSignature(data.data.signature)
    //             })
    //         })
    // }, [amount])

    return (<>
        <div className={css.wrapper}>

            {!redsysSignature ? <div className={css.container}>
                <YoigoSubmitDetails amount={amount} plan={getCurrent('tplan')} />
                {/* {uploadedSignature && <img src={`http://localhost:8000/signatures/${uploadedSignature}`} />} */}
                {!uploadedSignature && <>

                    <div className={css.info}>
                        <h2><TText>requireSignature</TText></h2>
                        <h2><TText>requireSignatureIdentical</TText></h2>
                    </div>
                    <CanvasDraw onChange={() => setSignature(canvasRef.current.getDataURL('png'))} ref={canvasRef} className={css.canvas} {...canvasProps} />
                    <div className="button" style={{ background: 'salmon' }} onClick={() => canvasRef.current.clear()}><TText>clearButton</TText></div>
                    <div className="button" style={{ background: 'orange' }} onClick={() => canvasRef.current.undo()}><TText>undoButton</TText></div>
                </>}
                {!uploadedSignature && <div className="button" onClick={() => submitSignature()}><TText>sign</TText></div>}
                {submitButton && <div className="button">

                    <span onClick={() => handleSubmit()}><TText>sendRequestButton</TText></span>
                </div>}
            </div> :
                <div className={css.container}>

                    <YoigoSubmitDetails amount={amount} plan={getCurrent('tplan')} />
                    {/* {redsysSignature && <PaymentForm signature={redsysSignature} params={params} />} */}
                    <span className="button" onClick={() => handleSubmit()}><TText>sendRequestButton</TText></span>

                </div>
            }

        </div>
    </>)

}


    // const algo = () => {
    //     const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    //     svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    //     svg.setAttribute('width', canvas.width);
    //     svg.setAttribute('height', canvas.height);

    //     const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
    //     foreignObject.setAttribute('width', '100%');
    //     foreignObject.setAttribute('height', '100%');

    //     const image = document.createElement('img');
    //     image.setAttribute('src', dataUrl);
    //     image.setAttribute('style', 'image-rendering: pixelated;');

    //     foreignObject.appendChild(image);
    //     svg.appendChild(foreignObject);

    //     console.log(svg.outerHTML);
    // }