import { TText } from '../../pages/Translations/langGetter'
import css from './Details.module.css'
import { getCurrent } from './userFieldHandler'
import { yoigoPlans } from './YoigoPlans'
export const YoigoSubmitDetails = () => {

    const current = getCurrent()
    const plan = yoigoPlans.filter(el => el.caption === current.tplan)[0]
    return <>
        <div className={css.wrapper}>
            <div className={css.col}>
                <div className={css.row}>
                    <TText>yourPlan</TText> : {getCurrent('tplan')}
                </div>
                <div className={css.row}>
                    <TText>balanceOnSimCard</TText>: 10€
                </div>
                <div className={css.row}>
                    <TText>toPay</TText> {plan.cost >= 10 ? plan.cost - 10 : plan.cost}€
                </div>
            </div>
        </div>
    </>
}