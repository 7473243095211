export const updateUser = (field, value) => {

    const current = JSON.parse(localStorage.getItem('user')) || {}

    current[field] = value
    localStorage.setItem('user', JSON.stringify(current))
}

export const getCurrent = (field) => {
    if (!field) return JSON.parse(localStorage.getItem('user'))
    else {
        const current = JSON.parse(localStorage.getItem('user')) || {}
        return current[field]
    }
}