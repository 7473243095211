
import { useRef } from 'react'
import CanvasDraw from 'react-canvas-draw'
import css from './Test.module.css'
export const Test = () => {
    const refe = useRef(null)

    return (
        <>
            <div className={css.wrapper}>

                <CanvasDraw ref={refe} />
                <button
                    onClick={() => {
                        localStorage.setItem(
                            "firma2",
                            refe.current.getDataURL()
                        );
                        console.log(refe.current.getDataURL())

                    }}
                >
                    Save
                </button>

                <button
                    onClick={() => {
                        refe.current.loadSaveData(
                            localStorage.getItem("firma")
                        );
                    }}
                >
                    Load what you saved previously into the following canvas. Either by
                    calling `loadSaveData()` on the component's reference or passing it
                    the `saveData` prop:
                </button>

            </div>
        </>
    )
}